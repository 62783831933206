import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import Footer from "../../footer/footer";
import CustomerInformation from "./CustomerInformation";
import PaymentInformation from "./PaymentInformation";
import eurekaImg from "../../../assets/image/EurekaTrip.png";
import "../../../assets/css/checkout.css";
import { Building, People, Clock, BagX } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import AdvancePaymentInfo from "./AdvancePaymentInfo";

// Function to load Razorpay SDK dynamically
const loadRazorpayScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

const RazorPay = ({ transactionData }) => {
  const location = useLocation();
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const BaseApi = process.env.REACT_APP_PAYMENT_SERVER_ENDPOINT;

  const [Schedules, setSchedules] = useState([]);

  const [paymentType, setPaymentType] = useState('');
  const [guestUserInfo, setGuestUserInfo] = useState({});
  

  

  const { tourId } = location.state || {};
  const [selectedSchedule, setSelectedSchedule] = useState(
    location.state?.selectedSchedule
  );
  const [Participants, setParticipants] = useState(
    location.state?.Participants
  );
  const [tourImage, setTourImage] = useState(null);
  const navigate = useNavigate();

  // Check if user is logged in
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const [formData, setFormData] = useState({});
  const [tourData, setTourData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [paymentResponseData, setPaymentResponseData] = useState();
  const [advpaymentResponseData, setAdvPaymentResponseData] = useState();


  const totalPrice =
    Participants * (selectedSchedule ? selectedSchedule.finalPrice : 0);

  useEffect(() => {
    // Check if userData exists, otherwise create guest user
    if (!userData) {
      createGuestUser().then((guestUser) => {
        if (guestUser) {
          setUserData(guestUser);
          setFormData({
            email: guestUser.email,
            fullName: guestUser.firstName,
            phoneNumber: guestUser.mobileNumber,
          });
        }
      });
    } else {
      setFormData({
        email: userData.email || "",
        fullName: userData.firstName || "",
        phoneNumber: userData.mobileNumber || "",
      });
    }
  }, [userData]);

  useEffect(() => {
    if (tourId) {
      fetchTourData(tourId);
    }
  }, [tourId]);

  // Function to create guest user if no user is logged in
  const createGuestUser = async () => {
    const guestUserData = {
      email: formData.email,
      fullName: formData.fullName,
      mobileNumber: formData.mobileNumber,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/save/guestUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(guestUserData),
      });

      if (!response.ok) {
        throw new Error("Failed to create guest user");
      }

      const responseData = await response.json();
      const guestUserId = responseData.data.guestUserId || responseData.data.userId;
      // Store guest user in localStorage and return the user data
      const guestUser = {
        userId: guestUserId,
        email: guestUserData.email,
        firstName: guestUserData.fullName,
        mobileNumber: guestUserData.mobileNumber,
      };

      localStorage.setItem("user", JSON.stringify(guestUser));
      return guestUser;
    } catch (error) {
      console.error("Error creating guest user:", error.message);
      return null;
    }
  };

  const fetchTourData = async (id) => {
    try {
      const response = await fetch(`${BaseAPI}/tour/${id}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTourData(data.data);
      if (data.data) {
        setSchedules(data.data.schedules);
      }

      if (data.data.tourAssets && data.data.tourAssets.length > 0) {
        setTourImage(data.data.tourAssets[0].assetUrl);
      }
    } catch (error) {
      console.error("Error fetching tour data:", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const guestUserData = {
        email: formData.email,
        fullName: formData.fullName,
        mobileNumber: formData.mobileNumber,
      };
      const response = await fetch(`${BaseAPI}/tour/save/guestUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(guestUserData),
      });
      if (!response.ok) {
        throw new Error("Failed to update tour");
      }
      const responseData = await response.json();
      console.log("==== responseData === ",responseData);
      const userId = responseData.data.guestUserId || responseData.data.userId;
      console.log("UserID ",userId)
      handlePayment(userId);
    } catch (error) {
      console.error("Error updating tour:", error);
    }
  };

  // Razorpay payment handler
  const handlePayment = async () => {
    try {
      let currentUser = userData;

      // If user is not logged in, create a guest user
      if (!currentUser || !currentUser.userId) {
        currentUser = await createGuestUser();
        if (!currentUser) {
          throw new Error("Guest user creation failed.");
        }
      }

      // Proceed with Razorpay payment
      const scriptLoaded = await loadRazorpayScript();
      if (!scriptLoaded) {
        toast.error("Razorpay SDK failed to load. Are you online?");
        return;
      }

      // Request to create a payment order
      const response = await fetch(`${BaseApi}/payment/razorpay/full/init`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: currentUser.userId,
          scheduleId: selectedSchedule.scheduleId,
          numberOfParticipants: Participants,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Response error:", errorText);
        throw new Error("Failed to create payment order");
      }

      const responseData = await response.json();
      const orderId = responseData.data.orderId;
      const currency = responseData.data.currency;
      const tourAmount = responseData.data.amount;
      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: tourAmount * 100,
        currency: currency,
        name: "Eureka Trip",
        description: "Tour Booking Payment",
        image: tourImage,
        order_id: orderId,

        handler: async (response) => {
          try {
            const paymentResponse = await fetch(
              `${BaseApi}/payment/razorpay/completed`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  razorpayPaymentId: response.razorpay_payment_id,
                  razorpayOrderId: orderId,
                  amount: totalPrice,
                  numberOfParticipants: Participants,
                }),
              }
            );

            if (paymentResponse.ok) {
              const paymentResponseData = await paymentResponse.json();
              setPaymentResponseData(paymentResponseData);
              setPaymentType('full')
              setCurrentStep(2);

            } else {
              const errorText = await paymentResponse.text();
              throw new Error(`Payment update failed: ${errorText}`);
            }
          } catch (error) {
            console.log(`Payment update error: ${error.message}`);
          }
        },
        prefill: {
          name: currentUser.firstName,
          email: currentUser.email,
          contact: currentUser.mobileNumber,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Payment handling error:", error);
      toast.error(`Payment handling error: ${error.message}`);
    }
  };
  const handleAdvancePaymentSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("form Data",formData);
      const guestUserData = {
        email: formData.email,
        fullName: formData.fullName,
        mobileNumber: formData.phoneNumber,
      };
      const response = await fetch(`${BaseAPI}/tour/save/guestUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(guestUserData),
      });
      if (!response.ok) {
        throw new Error("Failed to update tour");
      }
      const responseData = await response.json();
      const userId = responseData.data.guestUserId || responseData.data.userId;
      setGuestUserInfo(responseData.data);
      AdvancePayment()      
    } catch (error) {
      console.error("Error updating tour:", error);
    }
  };

  const AdvancePayment = async (userId) => {
    try {
      // Load Razorpay script
      const scriptLoaded = await loadRazorpayScript();
      if (!scriptLoaded) {
        toast.error("Razorpay SDK failed to load. Are you online?");
        return;
      }

      // Create a payment order for advance payment
      const response = await fetch(`${BaseApi}/payment/razorpay/advance/init`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: guestUserInfo.guestUserId || guestUserInfo.userId,
          scheduleId: selectedSchedule.scheduleId,
          numberOfParticipants: Participants,
          amount: totalPrice * 0.5,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Response error:", errorText);
        throw new Error("Failed to create advance payment order");
      }

      const responseData = await response.json();
      const orderId = responseData.data.orderId;
      const advanceAmount = responseData.data.amount;
      const currency = responseData.data.currency;
      responseData.data.scheduleId = selectedSchedule.scheduleId
      setAdvPaymentResponseData(responseData);
      setPaymentType('advance')

      // Razorpay options for advance payment
      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: advanceAmount * 100,
        currency: currency,
        name: "Eureka Trip",
        description: "Advance Payment for Tour Booking",
        image: eurekaImg,
        order_id: orderId,
        handler: async function (response) {
          try {
            // Success handler logic
            setCurrentStep(2);           
            // navigate("/order-history");
          } catch (error) {
            console.error("Error in payment completion:", error);
            toast.error(`Payment completion error: ${error.message}`);
          }
        },
        prefill: {
          name: formData.fullName,
          email: formData.email,
          contact: formData.phoneNumber,
        },
        theme: {
          color: "#F37254",
        },
      };

      // Open Razorpay payment modal
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Advance payment handling error:", error);
      toast.error(`Advance payment handling error: ${error.message}`);
    }
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (transactionData) {
      const { tourEntity, schedule, totalParticipants } = transactionData.data;

      if (selectedSchedule !== schedule) setSelectedSchedule(schedule);
      if (Participants !== totalParticipants)
        setParticipants(totalParticipants);
      if (currentStep !== 2) setCurrentStep(2);

      fetchTourData(tourEntity?.tourId);
    }
  }, [transactionData, selectedSchedule, Participants, currentStep]);

  const renderStep = () => {
    console.log("paymentResponseData========>", paymentResponseData);
    switch (currentStep) {
      case 1:
        return (
          <CustomerInformation
            selectedSchedule={selectedSchedule}
            formData={formData}
            handleAdvancePaymentSubmit={handleAdvancePaymentSubmit}
            handleSubmit={handleSubmit}
            handleChange={handleChange}

          />
        );
      case 2:
        return (
          <>
    {paymentType === 'full' && (
      <PaymentInformation
        paymentData={transactionData || paymentResponseData}
        handleNextStep={handleNextStep}
      />
    )}

    {paymentType === 'advance'  && (
      <AdvancePaymentInfo
        paymentData={advpaymentResponseData}
        userInfo={guestUserInfo}
        handleNextStep={handleNextStep}
      />
    )}
  </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="fixed-top">
        <div className="payment-navbar">
          <span
            className="eurekatrip-payment-text"
            onClick={() => navigate("/")}
          >
            <img
              className="eureka-payment-Logo mb-2"
              src={eurekaImg}
              alt="EurekaTrip"
            />
          </span>
        </div>
      </div>
      <Container fluid className="mt-3">
        <Row className="eureka-navbar-padding">
          <Col xs={12} md={6} className="order-md-1 order-sm-2 order-2">
            {renderStep()}
          </Col>
          <Col xs={12} md={6} className="order-md-2 order-sm-1 order-1">
            <Card className="mb-5 checkout-container ms-lg-5">
              <Card.Img
                variant="top"
                className="cardImage"
                src={tourImage || "default-image-url.jpg"}
              />
              <Card.Body>
                <Card.Title className="font-medium-title feature-title text-base ">
                  {tourData?.title}
                </Card.Title>
                <Card.Text>
                  <Row className="mb-2">
                    <Col xs={1}>
                      <Building className="text-bold" />
                    </Col>
                    <Col className="text-gray-700 font-feature-tour">
                      From {selectedSchedule?.startDate} To{" "}
                      {selectedSchedule?.endDate}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={1}>
                      <People className="text-bold" />
                    </Col>
                    <Col className="text-gray-700 font-feature-tour">
                      {Participants} People
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={1}>
                      <Clock className="text-bold" />
                    </Col>
                    <Col className="text-gray-700 font-feature-tour">
                      Duration: {selectedSchedule?.duration} Days{" "}
                      {/* {selectedSchedule?.durationTime} */}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={1}>
                      <BagX className="text-bold" />
                    </Col>
                    <Col className="text-gray-700 font-feature-tour">
                      {tourData?.cancellationPolicy?.description}
                    </Col>
                  </Row>
                </Card.Text>
                <Card.Footer className="text-muted">
                  <Row>
                    <Col className="font-medium-title">
                      <strong>Tickets</strong>
                    </Col>
                    <Col className="text-end font-medium">
                      {Participants} X {selectedSchedule?.currency}&nbsp;
                      {selectedSchedule?.finalPrice}{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="font-medium-title">
                      <strong>Total</strong>
                    </Col>
                    <Col className="text-end font-medium">
                      {" "}
                      {selectedSchedule?.currency}&nbsp; {totalPrice}
                    </Col>
                  </Row>
                </Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <div className="mt-4">
        <Footer />
      </div>
    </>
  );
};

export default RazorPay;
